/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// global css
@font-face {
  font-family: 'SeoulNamsan';
  src: url('/assets/font/SeoulNamsanL.woff') format('woff');
}
@font-face {
  font-family: 'SeoulHangang';
  src: url('/assets/font/SeoulHangangL.woff') format('woff');
}

$purple-primary : purple;
$purple-secondary : #7a2e8b;
$purple-bright : #e19bf1;

$yellow-primary: #f9b520;
$yellow-bright: #f3e35c;

$green-primary: #42a181;
$green-bright: #72f1c7;

$blue-primary: #00a6ff;
$blue-secondary: #319fdb;
$blue-bright: #7dd1ff;


html, body { 
  height: 100vh !important;
  overflow: hidden !important;
}
body { 
  margin: 0;
}

* {
  font-family: SeoulNamsan;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

i {
  font-family: FontAwesome;
}

.fa-plug {
  transform: rotate(45deg);
  transform-origin: 50%;
}

*:focus{
  outline : none;
}

.width-100 {
  width : 100%;
}
.height-100{
  height: 100%;
}

$outer-margin: 2rem;
$gutter-width: 1rem;
$gutter-compensation: ($gutter-width * 0.5) * -1;
$half-gutter-width: $gutter-width * 0.5;
$xl: 1200px;
$lg: 996px;
$md: 768px;
$sm: 544px;
$columns: 12;

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number, ($number * 0 + 1);
  }

  @return $number;
}

.container-fluid, .container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: $outer-margin;
  padding-left: $outer-margin;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

[class^=col-] {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}


@mixin generateColumnWidths($className) {
  @for $i from 1 through $columns {
    .col-#{$className}-#{$i} {
      flex-basis: calc($i / $columns * 100%);
      max-width: calc($i / $columns * 100%);
    }
  }
}

@mixin generateOffset($className) {
  @for $i from 1 through $columns {
    .col-#{$className}-offset-#{$i} {
      margin-left: calc($i / $columns * 100%);
    }
  }
}

@mixin helperClasses($className) {
  .col-#{$className} {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-#{$className} {
    justify-content: flex-start;
    text-align: start;
  }
  .center-#{$className} {
    justify-content: flex-start;
    text-align: start;
  }
  .end-#{$className} {
    justify-content: flex-end;
    text-align: end;
  }
  .top-#{$className} {
    align-items: flex-start;
  }
  .middle-#{$className} {
    align-items: center;
  }
  .bottom-#{$className} {
    align-items: flex-end;
  }
  .around-#{$className} {
    justify-content: space-around;
  }
  .between-#{$className} {
    justify-content: space-between;
  }
  .first-#{$className} {
    order: -1;
  }
  .last-#{$className} {
    order: 1;
  }
}

@mixin makeGrid($sizes...) {
  @each $classname, $width in keywords($sizes) {
    $minwidth: strip-unit($width);
    @if $width {
      @media screen and (min-width: #{$minwidth}px) {
        .container {
          width: #{$minwidth}px;
        }
        
        @include generateColumnWidths($classname);
        @include generateOffset($classname);
        @include helperClasses($classname);
      }
    } @else {
      @include generateColumnWidths($classname);
      @include generateOffset($classname);
      @include helperClasses($classname);
    }
  }
}

@include makeGrid(
  $xs: null,
  $sm: $sm,
  $md: $md,
  $lg: $lg,
  $xl: $xl
);



// All Codepen Styling down here 
.box, .box-first, .box-large, .box-nested, .box-row {
  position: relative;
  box-sizing: border-box;
  min-height: 1rem;
  margin-bottom: 0;
  background: #007FFF;
  border: 1px solid #FFF;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  color: #fff;
}


.box, .box-first, .box-large, .box-nested, .box-row {
  padding: 1rem;
}

.box-row {
  margin-bottom: 1rem;
}

.box-nested {
  background: #036;
  border-color: #007FFF;
}

.box-large {
  height: 8rem;
}

pre {
  overflow-x: auto;
  padding: 1.25em;
  border: 0px solid purple;
  border-left-width: 1px;
  border-left-width: 5px;
  margin: 1.6em 0;
  font-size: .875em;
  background: transparent;
  white-space: pre;
  word-wrap: normal;
}

code {
  color: #007FFF;
}




.pretty-line {
  display: block;

  .padding-wrapper{
    display: inline-block;

    .padding {
      width: 24px;
      display: inline-block;
      border-right: solid rgba(0, 0, 0, 0.05) 1px;
      height: 20px;
      margin-top: 0px;
      margin-bottom: -5px;
  
      &:last-child {
        border : none;
      }
    }
  }

  .json-wrapper{
    display: inline-block;
  }
}

.indent{
  display: block;
  padding-left: 24px;
  border-left: 1px dotted #bbb;
  margin-left: 2px;
}

.json{
  pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px; 
  }
  .string { 
    color: #0B7500; 
  }
  .number { 
    font-weight: bold;
    color: #1A01CC; 
  }
  .boolean {
    font-weight: bold;
    color: #1A01CC; 
  }
  .null { 
    font-weight: bold;
    // background-color: #1A01CC; 
    color : #cd201f;
  }
  .key { 
    color: #000000; 
  }
}


$ap-primary-color: #59c4ff;

.dg.ac{
  z-index: 10 !important;
}



.sb-point {
  color: purple;

  &:hover{
    color: rgb(229, 106, 229) !important;
  }

  i {
    margin-left: 2px;
    transform: scale(0.9);
  }
}

.sb-point-bright {
  color: rgb(229, 106, 229);

  &:hover{
    color: white !important;
  }

  i {
    margin-left: 2px;
    transform: scale(0.9);
  }
}

.sb-ticket{
  color: #42a181;

  &:hover{
    color: #72f1c7 !important;
  }

  i{
    margin-right: 2px;
  }
}

.sb-point.dark{
  color: rgb(229, 106, 229);

  &:hover {
    color: purple  !important;
  }
}
.sb-ticket.dark{
  color: rgb(114, 241, 199);

  &:hover {
    color: rgb(66, 161, 129)  !important;
  }
}

.warning-msg {
  color : red;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 12px 0;
}



*:focus {
  outline: none;
}

.hidden{
  display: none;
}

* {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
*::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, Opera*/
}







/* common */
.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;

  &::before, &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid purple;
  }
  span {
    z-index: 10;
    position: absolute;
    display: block;
    width: 175px;
    padding: 4px 0;
    color: white;
    background-color: purple;    
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.75);
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
  }
}

.ribbon-red {
  &::before, &::after {
    border-color: black;
  }
  span {
    background-color: black;
  }
}

.ribbon-darkred {
  &::before, &::after {
    border-color: darkred;
  }
  span {
    background-color: darkred;
  }
}

/* top left*/
.ribbon-top-left {
  top: -4px;
  left: -4px;

  &::before {
    top: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  &::after {
    bottom: 0;
    left: 0;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  
  span {
    right: -30px;
    top: 25px;
    transform: rotate(-45deg);
  }
}
/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.red{
  color:red;
}


ul{
  padding-inline-start: unset;
}



.my-profile {
  display: flex;
  width: 100%;
  height: 48px;
  margin-bottom: 12px;
  padding: 0 24px;
  
  .my-profile-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: auto 0;
  }
  .my-profile-body {
    margin: auto 0 auto 12px;

    .my-profile-name {
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }
    .my-profile-message {
      font-size: 12px;
      font-weight: 400;
      color: #aaa;
    }
  }
  .my-profile-option {
    margin: auto;
    margin-right: 0;
  }
}

.chat-time {
  color:white;
  margin: -4px;
  padding: 4px;
  background-color: rgb(87, 155, 194);
  font-weight: bold;
  cursor: pointer;
}
.chat-link {
  color:white;
  margin: -4px;
  padding: 4px;
  color: rgb(87, 155, 194);
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.chat-youtube-link {
  color:white;
  margin: -4px;
  padding: 4px;
  background-color: rgb(190, 87, 194);
  font-weight: bold;
  cursor: pointer;
}
.chat-iamge {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.chat-audio {
  width: 100%;
  height: 64px;
  cursor: pointer;
}


.dialog-padding-0 .mat-dialog-container {
  padding: 0;
  max-height: 100vh;
}
.dialog-padding-horizon-0 .mat-dialog-container {
  padding-left: 0;
  padding-right: 0;
}
.dialog-transparent .mat-dialog-container {
  padding: 0;
  margin: 0;
  background: transparent;
  box-shadow: none;
}
.dialog-height-100vh .mat-dialog-content {
  max-height: 100vh !important;
  margin: auto;
  overflow-y: scroll;
}
.padding-horizon-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

#stage-dat-gui {
  position: absolute;
  top: 30px;
  left: 30px;
  bottom: 30px;
  overflow: scroll;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}